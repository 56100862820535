import dom from "../helpers/dom";
import velocity from "velocity-animate";
import "velocity-animate/velocity.ui";

export default class LogoAnimated {
  constructor(el) {
    this.el = el;
    this.isEocActive = el.classList.contains("eoc_active");
    this.threshold = this.isEocActive ? 100 : 180;
    this.state = "init";

    if (!dom.hasClass(el, "preload")) dom.addClass(el, "preload");

    this.logo = {
      david: el.querySelector(".david"),
      hockney: el.querySelector(".hockney"),
      foundation: el.querySelector(".foundation")
    };

    // Run initial check in timeout to ensure Safari catches it
    window.setTimeout(() => {
      this.checkState(this.state);
    }, 200);

    window.addEventListener("scroll", () => {
      this.checkState(this.state);
    });
  }

  checkState(currentState) {
    const top = window.pageYOffset < this.threshold;
    const eocThreshold = window.innerWidth < 800;

    switch (currentState) {
      case "init":
        this.state = "transition";

        if (!top || eocThreshold && this.isEocActive) {
          this.tuck()
        } else {
          this.startAnimating();
        }
        break;
      case "animating":
        if (!top) {
          this.state = "transition";
          this.tuck(true);
        }
        break;
      case "corner":
        break;
      default:
        break;
    }
  }

  startAnimating() {
    dom.addClass(this.el, "init");
    dom.removeClass(this.el, "preload");

    setTimeout(() => {
      this.state = "animating";

      if (!this.isEocActive) {
        this.startIdle();
      } else {
        return;
      }

      this.checkState(this.state);
    }, 700);
  }

  tuck(transition) {
    dom.removeClass(this.el, "preload");
    this.pop(this.logo.hockney);

    this.freezeAnimate([
      this.logo.david,
      this.logo.hockney,
      this.logo.foundation
    ]);

    if (transition) dom.addClass(this.el, "transition");
    dom.addClass(this.el, "tuck");

    setTimeout(() => {
      dom.removeClass(this.el, "transition");
      this.el.addEventListener("click", () => {
        window.location.href = "/";
      });
    }, 1200);
  }

  startCorner() {
    this.state = "corner";
    dom.addClass(this.el, "corner");
  }

  startIdle() {
    this.idleAnimate(this.logo.david, -1, 0.15);
    this.idleAnimate(this.logo.hockney, 1, 0.15, 500);
    this.idleAnimate(this.logo.foundation, -1, 0.1, 800);
  }

  freezeAnimate(element) {
    velocity(element, "stop");
  }

  pop(element) {
    if (dom.hasClass(element, "back")) {
      dom.removeClass(element, "back");
      dom.addClass(element, "front");
    }
  }

  idleAnimate(element, direction, speed, delay = 0) {
    const { left, width } = element.getBoundingClientRect();
    const windowWidth = window.innerWidth;

    const initTravel = direction > 0 ? windowWidth - left : -left - width;
    const loopStart = direction > 0 ? -width : windowWidth;

    const startLoop = () => {
      velocity.RunSequence(seqLoop);
    };

    const seqLoop = [
      {
        e: element,
        p: {
          left: loopStart + "px"
        },
        o: {
          duration: 0,
          easing: "linear"
        }
      },
      {
        e: element,
        p: {
          left: direction * windowWidth
        },
        o: {
          duration: windowWidth / speed,
          easing: "linear",
          complete: startLoop
        }
      }
    ];

    const seqInit = [
      {
        e: element,
        p: {
          left: `+=${initTravel}px`
        },
        o: {
          duration: Math.abs(initTravel) / speed,
          easing: "ease-in",
          complete: startLoop
        }
      }
    ];

    setTimeout(() => {
      velocity.RunSequence(seqInit);
    }, delay);
  }
}
